<template>
  <video
    v-if="image.isGif"
    class="pointer-events-none"
    autoplay
    loop
    muted
    playsinline
  >
    <source
      :src="image.mp4Path"
      type="video/mp4"
    />
  </video>
  <NuxtImg
    v-else
    :src="image.cleanUrl"
    v-bind="$attrs"
    alt="Image"
  />
</template>

<script>
  import { imageData } from '@/utils/images'

  export default {
    name: 'SalveImage',
    props: {
      url: {
        type: String,
        required: true
      },
      sizes: {
        type: String
      },
      modifiers: {
        type: Object,
        required: false,
        default: null
      }
    },
    computed: {
      image() {
        return this.$correctedImageUrl(this.url, this.sizes, this.modifiers)
      }
    }
  }
</script>
