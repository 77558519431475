<template>
  <div>
    <div
      v-for="(message, i) in Object.values(messages)"
      :key="i"
    >
      <PageHeaderMessageBar :message="message" />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'SystemMessages',

    props: {
      type: {
        type: String,
        default: 'general'
      }
    },

    computed: {
      ...mapGetters('notifications', ['xNotificationsByType']),

      messages() {
        return this.xNotificationsByType(this.type)
      }
    }
  }
</script>
