<template>
  <transition name="slide-fade">
    <div>
      <div
        :style="[{ background: message.color }]"
        class="flex text-1xl text-white alert top-0 z-50 self-start py-1 px-5"
      >
        <div class="flex-grow login-bar min-h-12 w-full relative left-0 z-50">
          <div class="text-center flex justify-center items-center h-full">
            <span class="">
              {{ message.message }}
            </span>
            <ul>
              <li
                v-for="(item, i) in message.list"
                :key="i"
              >
                - {{ item }}
              </li>
            </ul>
          </div>
        </div>

        <div
          class="flex-none justify-end"
          @click="removeMessage"
        >
          <IconCloseModal class="cursor-pointer relative -top-1 mr-3 mt-2" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { mapMutations } from 'vuex'

  export default {
    name: 'LoginBar',

    props: {
      message: {
        type: Object,
        required: true
      }
    },

    computed: {
      slotText() {
        return this.$slots
      }
    },

    methods: {
      ...mapMutations('notifications', ['REMOVE_MESSAGE']),

      removeMessage() {
        console.log('removing message', this.message.index)
        this.REMOVE_MESSAGE(this.message.index)
      }
    }
  }
</script>

<style scoped lang="postcss">
  .alert {
    position: fixed;
    width: 100vw;

    top: 0;
    left: 0;
    z-index: 11;
    transition: all 0.35s ease;
    &.vue-fixed-header--isFixed {
      left: 0;
      top: 76px;
    }
  }

  .login-bar {
    @apply font-main;
    @apply uppercase text-white text-center font-bold;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.25s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .slide-fade-enter-active {
    transition: all 3s ease;
  }

  .slide-fade-leave-active {
    transition: all 5s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(20px);
    opacity: 0;
  }
</style>
